<template>
    <div class="sidebar">
        <transition name="slide">
            <div 
                class="sidebar__content"
                :class="{ active: isSidebarOpen }"
                >
                <slot></slot>
            </div>
        </transition>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    export default {
        name: "Sidebar",
        computed: mapGetters(["isSidebarOpen"]),
    }
</script>