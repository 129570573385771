<template> 
    <Modal />
    <footer class="footer">
        <div class="container">
            <h4 class="footer__title title" id="contacts">
                Контакты
            </h4>
            <p class="footer__contact-text">Вы можете написать нам письмо на почту <a href="mailto:blago7@list.ru" class="footer__contact email">blago7@list.ru</a></p>
            <a href="tel:+79670583170" class="footer__contact tel">+7 (967) 058-31-70</a>
            <ul class="position__list">
                <li 
                    class="position__item"
                    v-for="position in positions"
                    :key="position.id"
                >
                    <h6 class="position__title" >{{ position.text }}</h6>
                    <div class="personal__list">
                        <p 
                            class="personal__item"                         
                            v-for="personal in position.personals"
                            :key="personal.id"
                        >{{ personal }}</p>
                    </div>
                </li>
            </ul>
        </div>
    </footer>
</template>

<script>
    import Modal from '@/components/Modal.vue';
    
    export default {
        name: "Footer",
        data() {
            return {
                positions: [
                    {
                    text: "Совет фонда:",
                    personals: [
                        "Олищук Людмила Дмитриевна",
                        "Еремичев Андрей Анатольевич",
                        "Серафимов Артем Владимирович",
                    ],
                    },
                    {
                    text: "Попечительский Совет фонда:",
                    personals: [
                        "Данильчук Юрий Владимирович",
                        "Дмитриенко Адам Михайлович",
                    ],
                    },
                    {
                    text: "Директор фонда:",
                    personals: ["Людмила Дмитриевна Олищук"],
                    },
                    {
                    text: "Главный бухгалтер фонда:",
                    personals: ["Миркулова Елена Сергеевна"],
                    },
                ],
            }
        },
        components: {
            Modal,
        }
    }
</script>
