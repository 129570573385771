import { createStore } from 'vuex'
import sidebar from './modules/sidebar'
import modals from './modules/modals'
import getContent from './modules/getContent'

export default createStore({
  modules: {
    sidebar,
    modals,
    getContent
  }
})
