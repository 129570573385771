<template>
    
    <nav class="nav">
        <ul class="menu">
            <li 
                class="menu__item"
                v-for="(menuItem, index) in menuList"
                :key="index"
            >
                <router-link 
                    :to="menuItem.href" 
                    class="menu__link"
                    v-if="isSidebarOpen"
                    @click="closeSidebar"
                >
                    {{ menuItem.name }}
                </router-link>
                <router-link 
                    :to="menuItem.href" 
                    class="menu__link"
                    v-else
                >
                    {{ menuItem.name }}
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex'
    export default {
        name: "Menu",
        props: ["menuList"],
        computed: mapGetters(["isSidebarOpen"]),
        methods: {
            ...mapMutations(["closeSidebar"])
        }
    }
</script>
