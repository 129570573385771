<template>
	<div
		v-if="isOpenModal && getImgSource"
		:class="`modal ${isOpenModal?'modal-active':''}`"
		@click.self="closeModal"
	>
		<div class="modal__content">
			<button @click="closeModal">&times;</button>
			<div class="modal__img-wrap">
                <img 
                    :src="getImgSource.src" :alt="getImgSource.alt" class="modal__img">
            </div>
		</div>
	</div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
	
    export default {
		name: "Modal",
		computed: {
            ...mapGetters(["isOpenModal", "getImgSource"])
        },
		methods: {
			...mapActions(["closeModal"])
		},
		beforeUnmount() {
			if (this.isOpenModal) this.close()
		}
	}
</script>
