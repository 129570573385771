export default {
    actions: {
        openModal: ({ commit }, payload) => {
            commit('OPEN', payload);
        },
        closeModal: ({ commit }) => commit('CLOSE'),
    },
    mutations: {
        OPEN: (state, payload) => {
            state.imgDatas = payload;
            state.isOpenModal = true;
        },
        CLOSE: (state) => state.isOpenModal = false
    },
    state: {
        isOpenModal: false,
        imgDatas: {}
    },
    getters: {
        isOpenModal(state) { return state.isOpenModal; },
        getImgSource(state) {return state.imgDatas;}
    }
} 