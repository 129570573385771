import axios from 'axios'

export default {
    actions: {
        fetchContent: async ({ commit }) => {
            commit('LOADING')
            await axios.get('https://strapi.energogroup.org' + '/anastasis')
            .then(response => {
                commit('CONTENT_LOAD', response.data);
            })
            .catch(error => {
                console.log(error)
                commit('ERROR')
            });
        }
    },
    mutations: {
        LOADING: (state) => {
            state.status = 'loading';
        },
        CONTENT_LOAD: (state, payload) => {
            state.status = 'success';
            state.content = payload;
        },
        ERROR: (state) => {
            state.status = 'error'
        }
    },
    state: {
        content: [],
        status: ''
    },
    getters: {
        getContent(state) {
            return state.content;
        },
        status(state) {
            return state.status;   
        },
        getNews(state) {
            return state.content.news;
        },
        getNewsId: (state) => (id) => {
            return state.content.news.find(item => item.id === id);
        }
    }
} 
