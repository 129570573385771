<template>
    <section class="section section__error">
        <div class="container">
            <h1 class="error__title title">Ошибка</h1>
            <div class="error__description text">
                <p>К сожалению на сайте произошла ошибка! Попробуйте позже.</p>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        
    }
</script>