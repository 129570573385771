<template>
    <div 
        class="menu__btn"
        :class="{ 'active': isSidebarOpen }"
        @click.prevent="toggleSidebar"
    >
        <span class="menu-btn__line menu-btn__line-1"></span>
        <span class="menu-btn__line menu-btn__line-2"></span>
        <span class="menu-btn__line menu-btn__line-3"></span> 
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex'
    export default {
        name: "Help",
        computed: mapGetters(["isSidebarOpen"]),
        methods: {
            ...mapMutations(["toggleSidebar"]),
        }
    }
</script>