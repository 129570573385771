<template>
    <header class="header">
    <div class="container">
      <div class="header__content">
        <Menu :menuList="menuLeft"/>
         <div class="header__logo">
            <router-link :to="{'name': 'Home'}" class="logo__link">
                <img 
                  :src="logo.src" 
                  :alt="logo.alt"
                  class="logo__img"
                >
            </router-link>
          </div>
        <Menu :menuList="menuRight"/>
        <Burger />
      </div>
    </div>
    <Sidebar>
        <Menu :menuList="menuList"/>
    </Sidebar>
  </header>
</template>

<script>
    import Burger from '@/components/sidebar/Burger';
    import Sidebar from '@/components/sidebar/Sidebar';
    import Menu from '@/components/Menu.vue';
    export default {
        name: "Header",
        components: { 
            Burger, 
            Sidebar,
            Menu 
        },
        data() {
            return {
                menuList: [
                    { name: "Цели фонда", href: "/#mission", },
                    { name: "Кому помогаем", href: "/#helping" },
                    { name: "Новости", href: "/news" },
                    { name: "Фотоотчет", href: "/#gallery" },
                    { name: "Описание", href: "/#activity" },
                    { name: "Контакты", href: "/#contacts" },
                ],
                menuLeft: [],
                menuRight: [],
                logo: {
                    src: require('../img/fractions.png'), 
                    alt: "Логотип",
                }
            };
        },
        created() {
            const arrLength = this.menuList.length;
            
            this.menuLeft = this.menuList.slice(0, (arrLength/2));
            this.menuRight = this.menuList.slice(3, 6);
        }
    }
</script>
