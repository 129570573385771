export default {
    actions: {},
    mutations: {
        setSidebar(state,isOpen = false) {
            return state.isSidebarOpen = isOpen;
        },
        toggleSidebar(state) {
            return state.isSidebarOpen = !state.isSidebarOpen;
        },
        closeSidebar(state) {
            return state.isSidebarOpen = false;
        }
    },
    state: {
        isSidebarOpen: false
    },
    getters: {
        isSidebarOpen(state) {
            return state.isSidebarOpen;
        }
    }
}

