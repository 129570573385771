<template>
    <Header />
      <loading v-if="status === 'loading'"/>
      <error v-else-if="status === 'error'"/>
      <router-view v-else/>
    <Footer />
</template>
<script>
import Loading from '@/components/Loading.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapGetters, mapActions } from 'vuex';
import Error from '@/components/Error.vue';

export default {
  components: {
    Loading,
    Header,
    Footer,
    Error,
  },
  computed: {
    ...mapGetters(['status']),
  },
  methods: {
    ...mapActions(['fetchContent'])
  },
  created() {
    this.fetchContent();
  }
};
</script>